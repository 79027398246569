'use client';

import React, { ReactElement } from 'react';
import { css } from 'styled-system/css';
export default function Error({
  reset
}: {
  error: Error;
  reset: () => void;
}): ReactElement {
  return <div className={styles.main} data-sentry-component="Error" data-sentry-source-file="error.tsx">
    <h2 className={styles.title}>Something went wrong!</h2>
    <button onClick={() => reset()}>Try again</button>
  </div>;
}
const styles = {
  main: css({
    p: 8
  }),
  title: css({
    mb: 2
  })
};